import Vue from 'vue'
// axios
import axios from 'axios'
// eslint-disable-next-line import/no-cycle
import router from '../router'

import i18n from '@/libs/i18n'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
})

// those lines will catch your status returend requests
axiosIns.interceptors.response.use(
  response => response,
  error => {
    // check if error occurred from messaging service ignore it.
    if (error.config && error.config.url.startsWith(process.env.VUE_APP_BASE_MESSAGING_URL)) {
      return false
    }
    if (error?.response?.status === 406) {
      return Promise.reject(error.response)
    }
    if (error?.response?.status) {
      if (error?.response?.status === 503) {
        if (router.history.current.fullPath !== `/${i18n.locale}/maintenance`) {
          router.push(`/${i18n.locale}/maintenance`)
        }
        return Promise.reject(error.response)
      }
      if (error?.response?.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('authenticated')
        router.push(`/${i18n.locale}/login`)
        return JSON.parse(JSON.stringify(error)).message
      }
      Vue.swal({
        title: error?.response?.data?.message,
        icon: 'error',
        showConfirmButton: false,
        showCloseButton: true,
      })
      return Promise.reject(error.response)
    }
    if (error?.response?.status === 401) {
      console.log(error?.response)
      localStorage.removeItem('token')
      localStorage.removeItem('authenticated')
      router.push(`/${i18n.locale}/login`)
      return JSON.parse(JSON.stringify(error)).message
    }
    if (error?.response?.data?.message) {
      Vue.swal({
        title: error.response?.data?.message,
        html: error.response?.data?.errors ? Object.values(error?.response?.data?.errors).join('<br><br>') : '',
        icon: 'error',
        showConfirmButton: false,
        showCloseButton: true,
      })
    }
    return Promise.reject(error.response)
  },
)
axiosIns.interceptors.request.use(async config => {
  const lang = await i18n.locale
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
  // eslint-disable-next-line no-param-reassign
  config.headers['Cache-Control'] = 'no-cache'
  // eslint-disable-next-line no-param-reassign
  config.headers['APP-LANG'] = lang
  return config
})

Vue.prototype.$http = axiosIns
export default axiosIns
